export default function HeadToHead({ name1, name2, data }) {
  if (name1 === name2) {
    return "-"
  }

  // Opponents have never played each other
  if (!data) {
    return "-"
  }

  const wins = data.wins ? data.wins : 0
  const losses = data.losses ? data.losses : 0
  const ties = data.ties ? data.ties : 0

  if (ties > 0) {
    return `${wins} - ${losses} - ${ties}`
  }

  return `${wins} - ${losses}`
}
