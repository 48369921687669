import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import OwnerStatsTable from "../components/owner-stats-table"
import HeadToHead from "../components/head-to-head"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

function HeadToHeadRow({ name, ownerNames, headToHead }) {
  return (
    <TableRow hover>
      <TableCell>{name}</TableCell>
      {ownerNames.map(owner => (
        <TableCell key={name + owner}>
          <HeadToHead name1={name} name2={owner} data={headToHead[owner]} />
        </TableCell>
      ))}
    </TableRow>
  )
}

function OwnersHeadToHead({ owners }) {
  const ownerNames = owners.map(owner => owner.name).sort()
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>-</TableCell>
            {ownerNames.map((owner, idx) => (
              <TableCell key={idx}>{owner}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {owners.map(owner => (
            <HeadToHeadRow
              key={owner.name}
              name={owner.name}
              ownerNames={ownerNames}
              headToHead={JSON.parse(owner.headToHead)}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default function Owners({ data }) {
  const owners = data.ccffl.listOwners.items.sort((a, b) =>
    a.name > b.name ? 1 : -1
  )

  return (
    <Layout>
      <h1>Owners</h1>
      <h2>Summary</h2>
      <OwnerStatsTable items={owners} showName={true} />
      <h2>Head to Head Results</h2>
      <OwnersHeadToHead owners={owners} />
      <br />
    </Layout>
  )
}

export const query = graphql`
  query {
    ccffl {
      listOwners {
        items {
          name
          seasonWins
          seasonLosses
          seasonTies
          seasonPointsFor
          seasonPointsAgainst
          playoffWins
          playoffLosses
          championshipWins
          championshipLosses
          bsbWins
          bsbLosses
          headToHead
          teams {
            items {
              seasonWins
              seasonTies
              seasonPointsFor
              seasonPointsAgainst
              seasonLosses
              season
              playoffWins
              playoffLosses
              name
              ownerName
              id
              headToHead
              divisionName
              championshipWins
              championshipLosses
              bsbWins
              bsbLosses
            }
          }
        }
      }
    }
  }
`
